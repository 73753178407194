<template>
  <div class="home">
    <img class="bg-img" src="../assets/space-animation.gif" />
    <InventoryComp />
  </div>
</template>

<script>
// @ is an alias to /src
import InventoryComp from "@/components/InventoryComp.vue";

export default {
  name: "Inventory",
  components: {
    InventoryComp,
  },
};
</script>
