<template v-if="astrobro">
  <div class="modal-container">
    <div class="modal inventory stargrid">
      <div class="modal-close-container" @click="closeModal(true)">
        <i class="fa fa-times"></i>
      </div>
      <div class="video-container" v-if="mute">
      <video class="fullscreen-video" id="myVideo" autoplay="autoplay" muted controls>
        <source src="../assets/hatching.mp4">
        Your browser does not support the video tag. But don't worry, you're egg has hatched!
    </video>
    </div>
    <div class="video-container" v-else>
      <video class="fullscreen-video" id="myVideo" autoplay="autoplay" controls>
        <source src="../assets/hatching.mp4">
        Your browser does not support the video tag. But don't worry, you're egg has hatched!
    </video>
    </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalHatch",
  props: {
    astrobro: Object,
    mute: Boolean
  },
  data() {
    return {
      hasHatched: 0,
      timer: null
    };
  },
  created() {
   setTimeout(() => 
     this.closeModal(true)  
     , 14500);
  },
  methods: {
    closeModal(event) {
      //console.log('clicking')
      this.$emit("closed", event);
    },
  },
  computed: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fullscreen-video {
    position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
}
.modal-container::before {
  content: "";
  position: fixed;
  background: #281038c7;
  width: 100%;
  height: 100%;
  display: block;
  bottom: 50%;
  right: 50%;
  transform: translateX(50%) translateY(50%);
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: overlay;
    z-index: 100;
}

.modal.inventory.stargrid > h1 {
  display: inline;
}
.modal .inventory-container img {
  width: 100%;
  /*height: 100%;*/
  border: solid 2px #ffffff;
  border-radius: 5px;
}
.modal .inventory-container {
  display: flex;
  clear: both;
      flex-flow: wrap;
}
.modal .inventory-container>div {
    /*min-width: 340px;*/
    flex: 1;
    margin: 10px;
}
.modal-close-container {
    font-size: 40px;
    padding: 0px 10px 10px 10px;
    float: right;
    cursor: pointer;
    z-index: 99999;
    position: absolute;
    width: 100%;
    text-align: right;
}
.modal-close-container:hover {
    color: #c8ffbc;
    }
.modal {
position: relative;
    width: 80%;
        top: 5vh;
    /* bottom: 50%; */
    /* right: 50%; */
    /* transform: translateX(50%) translateY(50%); */
    background: #141422;
    border-radius: 5px;
    border: solid 2px #20a15a;
    box-shadow: #ffffff 0px 8px 7px -10px, #0aff52 0px 10px 25px -12px, #005eaa 0px 10px 40px -15px;
        margin-bottom: 100px!important;
}
.item-content {
    margin: 0 0px 0 20px;
    background: white;
    color: #43436a;
    padding: 20px;
    font-weight: bold;
    border-radius: 5px;
}

.item-content h1 {
  padding: 10px;
  margin: 0;
  background: #a12c20;
  color: #ffffff;
  font-weight: bold;
}
.attribute-container {
      display: inline-flex;
    flex-flow: wrap;
  /* padding: 10px; */
}
.attribute {
margin: 10px;
    background: #572578;
    padding: 5px 0px 5px 20px;
    border-radius: 25px;
    color: white;
    text-transform: capitalize;
    flex: auto;
    display: inline-flex;
    align-items: baseline;
}
.attribute > span {
    margin: 0px 4px 0px 10px;
    background: #7f35b0;
    padding: 7px 20px;
    border-radius: 25px;
    flex: auto;
}
.btn-container {
        /*display: inline-flex;*/
            display: flex;
    flex-flow: wrap;
}
.btn-container .button {
  flex: auto;
    padding: 7px 10px;
    background: #91f87b;
    color: #141422;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s;
        box-shadow: #ffffff 0px 0px 0px -10px, #0aff52 0px 0px 0px -12px, #005eaa 0px 0px 0px -15px;
}
.btn-container .button:hover {
    box-shadow: #ffffff 0px 8px 7px -10px, #0aff52 0px 10px 25px -12px, #005eaa 0px 10px 40px -15px;
    color: #141423;
    background: #c8ffbc;
}
div.inventory {
    margin: 0px auto;
}
</style>
