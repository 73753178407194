<template>
  <div class="inventory stargrid">
    <div class="thumb" @click="version = version + 1">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh thumbnails
    </div>
    <!--<div class="audio" v-if="mute" @click="mute = !mute">
      <i class="fa fa-volume-off" aria-hidden="true"></i>
      <span> Muted</span>
      <span class="subtext">click me!</span>
    </div>
    <div class="audio unmuted" @click="mute = !mute" v-else>
      <i class="fa fa-volume-up" aria-hidden="true"></i>
      <span> Unmuted</span>
      <span class="subtext">click me!</span>
    </div>-->
    <div class="audio unmuted credits" v-if="creditsTotal.result">
      <i class="fa " aria-hidden="true"></i>
      <span> GIGA
      </span>
      <span class="subtext">{{parseFloat(creditsTotal.result).toFixed(5)}}</span>
    </div>
    <h1>My Inventory:</h1>
    <template>
      <div class="inventory-container">
        <ul>
          <template v-for="(item, index) in dev_inventory.result">
            <li :key="index" @click="selectedIndex = index">
              <img :src="item.image_url+'?v='+version" />
              <h3>
                {{ item.name }}<span>Stored in {{ item.status }}</span>
              </h3>
              <p>
                Attributes:
                <template v-if="item.metadata">{{
                  Object.keys(
                    filterAttr(item.metadata, [
                      "description",
                      "external_url",
                      "image",
                      "name",
                    ])
                  ).length - 1
                }}</template>
                <template v-else>n/a</template>
              </p>
            </li>
          </template>
        </ul>
        <template v-if="Object.keys(dev_inventory).length === 0">
          <div class="metamask-container">
            <img
              class="metamask"
              @click="linkRegister()"
              src="../assets/metamask-logo.png"
            />
            <span>Click the fox to sign into IMX and Metamask</span>
          </div>
        </template>
        <template v-else>
          <div class="paginate">
            <div class="page-btn" @click="backPage()">Prev</div>
            <div class="page-active">{{ page + 1 }}</div>
            <div class="page-btn" @click="nextPage()">Next</div>
          </div>
          <!--<div class="opensea">
            <div class="page-active" @click="openSeasmy()">Check OpenSea</div>
          </div>-->
        </template>
      </div>
    </template>
    <template
      v-if="selectedIndex != null && dev_inventory.result[selectedIndex]"
    >
      <ModalItem
        :v="version"
        :mute="mute"
        :astrobro="dev_inventory.result[selectedIndex]"
        @closed="modalClose"
      />
    </template>
  </div>
</template>

<script>
import ModalItem from "@/components/ModalItem.vue";
import axios from 'axios';
    
export default {
  name: "InventoryComp",
  components: {
    ModalItem,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      creditsTotal: 0,
      version: 0,
      mute: false,
      cursors: [],
      linked: {},
      page: 0,
      selectedIndex: null,
      client: {},
      wallet: "",
      inventory: {},
      marketplace: {},
      interval: ''
    };
  },
  created() {
    // initialise an Immutable X Client to interact with apis more easily
    this.$imxClient
      .build({ publicApiUrl: process.env.VUE_APP_MAINNET_ENV_URL })
      .then((response) => (this.client = response));
  },
  mounted() {
    this.$nextTick(() => {
      this.linkRegister();
    });
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    filterAttr(attr, whitelist) {
      return Object.keys(attr)
        .filter((key) => !whitelist.includes(key))
        .reduce((obj, key) => {
          obj[key] = attr[key];
          return obj;
        }, {});
    },
    modalClose(value) {
      if (value) {
        this.selectedIndex = null;
      }
    },
    backPage() {
      const i = this.cursors.indexOf(this.inventory.cursor);
      console.log(i);
      if (i - 2 >= 0) {
        this.linked.then((res) => {
          this.client
            .getAssets({
              user: res.address,
              sell_orders: true,
              page_size: 10,
              collection: "0x9c77027170eda1808262809771067bee23830050",
              cursor: this.cursors[i - 2],
            })
            .then(
              (response) => (
                (this.inventory = response), (this.page = this.page - 1)
              )
            );
        });
      } else if (i == -1 || i - 2 < 0) {
        this.linked.then((res) => {
          this.client
            .getAssets({
              user: res.address,
              sell_orders: true,
              collection: "0x9c77027170eda1808262809771067bee23830050",
              page_size: 10,
            })
            .then((response) => ((this.inventory = response), (this.page = 0)));
        });
      }
    },
    addCursor(cur) {
      if (this.cursors.indexOf(cur) == -1) {
        this.cursors.push(cur);
      }
    },
    nextPage() {
      if (this.inventory.remaining == 1) {
        this.linked.then((res) => {
          this.client
            .getAssets({
              user: res.address,
              sell_orders: true,
              collection: "0x9c77027170eda1808262809771067bee23830050",
              page_size: 10,
              cursor: this.inventory.cursor,
            })
            .then(
              (response) => (
                (this.inventory = response),
                this.addCursor(response.cursor),
                (this.page = this.page + 1)
              )
            );
        });
      }
    },
    linkRegister() {
      // user signup to imx and get
      this.linked = this.link.setup({});
      this.linked.then((res) => {
        this.wallet = res.address;
        this.client
          .getAssets({
            user: res.address,
            //sell_orders: true,
            collection: "0x9c77027170eda1808262809771067bee23830050",
            page_size: 10,
          })
          .then(
            (response) => (
              (this.inventory = response),
              this.cursors.push(this.inventory.cursor),
              axios.get('https://api.astrobros-nft.com/credits/'+res.address).then(response => (this.creditsTotal = response.data)),
              this.interval = setInterval(() => {
                  axios.get('https://api.astrobros-nft.com/credits/'+res.address).then(response => (this.creditsTotal = response.data));
                  console.log ('refresh');
              }, 300000)
            )
          ).catch(err => {
            console.error(err);
            alert("Please try again, there is a problem connecting to IMX API. \n Check https://status.immutable.com/ for details");
          });
        /*this.client
          .getOrders({ status: this.$linkTypes.ImmutableOrderStatus.active })
          .then((response) => (this.marketplace = response));*/
      });
    },
    openSeasmy() {
      const options = {method: 'GET', headers: {'X-API-KEY': '44599c92e5c943ca9b18011101548896'}};
      //top_ownerships = current owner or top 10 owners if it has multiple per asset
      //fetch('https://api.opensea.io/api/v1/asset/0x495f947276749ce646f68ac8c248420045cb7b5e/103655063449144203324105366704944102701225888616451078897098848979429804212225/', options)
      //[0]['last_sale']['transaction']['from_account']['address'] //current owner for some reason
      fetch('https://api.opensea.io/api/v1/assets?order_direction=desc&offset=0&limit=20&collection=astrobros-s1', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    }
  },
  computed: {
    link() {
      return new this.$link(process.env.VUE_APP_MAINNET_LINK_URL);
    },
    alchemy() {
      return process.env.VUE_APP_ALCHEMY_API_KEY;
    },
    dev_inventory() {
      if ("cursor" in this.inventory) {
        return this.inventory;
      }
      return {};
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.thumb {
  position: absolute;
  left: 0;
  padding: 0 20px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  color: #5cf08a;
}
.audio.unmuted {
  color: #ff8f20;
}
span.subtext {
  display: block;
  font-size: smaller;
  color: white;
}
div.credits {
  cursor: default;
}
div.credits span.subtext {
    background: white;
    color: #8637de;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    font-size: 0.9em;
    font-weight: bold;
}
.audio {
  position: absolute;
  right: 0;
  padding: 0 20px;
  cursor: pointer;
}
img.metamask {
  display: block;
  margin: 0 auto;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}
.metamask-container {
  margin: 50px auto;
  color: white;
}
img.metamask:hover {
  background: #5cf08a;
}
.page-btn {
  padding: 3px 30px;
  cursor: pointer;
}
.page-active {
  background: #5e2781;
  padding: 3px 20px;
  border-radius: 5px;
}
.page-btn:hover {
  color: #b7ffce;
}
.paginate {
  display: inline-flex;
}
h3 {
  margin: 40px 0 0;
}
div.inventory-container > ul {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 24px 16px;
}
div.inventory-container > ul > li {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  border: solid 2px #20a15a;
  border-radius: 5px;
  background: #141422;
  color: #99f87b;
  padding: 10px;
  box-sizing: border-box;
  transition: box-shadow 0.1s;
}
div.inventory-container > ul > li:hover {
  box-shadow: #ffffff 0px 8px 7px -10px, #0aff52 0px 10px 25px -12px,
    #005eaa 0px 10px 40px -15px;
}
.inventory-container h1 {
  padding: 10px;
  margin: 0;
  background: #8637de;
  color: #ffffff;
}
.inventory-container h3 {
  padding: 10px;
  margin: 0;
  background: #a12c20;
  color: #ffffff;
  font-weight: bold;
}
div.inventory {
  width: 80%;
  margin: 60px auto;
  padding: 20px;
  box-sizing: border-box;
  border: solid 2px #5e2781;

  position: relative;
}
div.inventory-container h3 > span {
  font-size: 0.7em;
  margin-left: 10px;
  color: #ffffff;
  background: #6c6c6c;
  padding: 2px 10px;
  border-radius: 15px;
}
a {
  color: #42b983;
}
</style>
