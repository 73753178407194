<template>
  <div class="home">
    <div class="bg-img-container">
      <img class="fullsize" src="../assets/a-skelding-a-day-keeps-the-doctor-away.jpg" />
    </div>
    
    <div class="fw header-img">
      <img class="main-logo" src="https://api.astrobros-nft.com/public/astrobros/logos/astrobros-blank.png" />
    </div>
    <div class="astro-container">
      <div class="image-container">
        <!--<img class="bro-left" src="../assets/example-bros/1.gif" />
        <img class="bro-right" src="../assets/example-bros/2.gif" />-->
        <h2 class="subheading">Available now on</h2>
        <a
          href="https://mintable.app/store/Astrobros/0x9c77027170eda1808262809771067bee23830050"
          target="_blank"
          ><img class="inline" src="../assets/mintable_custom.png"
        /></a><br>
        <a
          href="https://market.x.immutable.com/assets?collection=0x9c77027170eda1808262809771067bee23830050"
          target="_blank"
          ><img class="inline" src="../assets/immutable-s.png"
        /></a>
        <a
          href="https://tokentrove.com/collection/Astrobros?sort=price-asc"
          target="_blank"
          ><img class="inline" src="../assets/tt.png"
        /></a>
      </div>
      <div class="main-text as-textbox">
        <div class="bro-container" @click="switchImage()">
          <div class="bro-cropper">
            <span class="interactive">Click Me!</span>
            <img class="bro bro-l" :src="getBro" />  
          </div>
        </div>
        <h1>
          10,000 randomly built, animated pixelart 'bros blasting off into
          dimensions unknown!
        </h1>
        <hr/>
        <p>
          These ultra muscle-bound, 80s styled behemoths are ready to traverse
          the Omniverse, crush the Skeldar and bathe in the glory of being the most powerful and aesthetic beings in existence...
        </p>
        <h2>
          all with <strong>0 fees</strong> powered by Ethereums first NFT focused L2,
          <a
            href="https://market.x.immutable.com/assets?collection=0x9c77027170eda1808262809771067bee23830050"
            target="_blank"
            >ImmutableX</a
          >!
        </h2>
       
        <div class="inline-img-container">
           <hr/>
        <img class="inline-right" src="../assets/credits.png" />
        <p>
          <span class="hilight">Owning and delisting an Astrobro isn't just a gnarly thing to do</span>, it also comes with the unique perk of generating Credits, an ERC-20 utility token that will enable you to customise, interact and build in the Astrobros universe and beyond!
        </p>
        <p>
          To view your Credits as a 'bro holder, just head to your <a href="./#/inventory">Inventory</a> and marvel at the muscle.
        </p>
        </div>
        
      </div>
  <h1>
          <span class="hilight">But wait</span> - there's more!...
        </h1>
      <div class="main-text as-textbox">
        <div class="bro-container alpha" @click="switchImage()">
          <div class="bro-cropper">
            <span class="interactive">Click Me!</span>
            <img class="bro bro-l alphabro" :src="getBro2" />  
          </div>
        </div>
        <h1>
          Alphabros - the Genesis collection
        </h1>
        <hr/>
        <p>
          <span class="hilight">This was it, the beginning of the Omniverse.</span>
          <br><br>These exclusive 39 Alphabros are a limited run of original pixel art pieces, each featuring a unique handmade one-of-a-kind Astrobro. <br><br>Owning one of these allows for exclusive behind-the-scenes access, additional utility token generation, able to create unique MegaBro traits and more. <br><br>Think you have the muscle? PAH! PROVE IT.
        </p>
        <h2>
          Buy now on <a
            href="https://opensea.io/collection/astrobros-s1"
            target="_blank"
            ><img class="inline-icon" src="../assets/os-i.png" />OpenSea</a
          >!
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import Inventory from "@/components/Inventory.vue";

import dayjs from "dayjs";

export default {
  name: "Home",
  components: {
    //Inventory,
  },
  data() {
    return {
      countdown: null,
      text: "Loading...",
      index: 1,
      index2: 2,
    };
  },

  mounted() {
    this.setupCountdownTimer();
    this.switchImage();
  },

  methods: {
    switchImage() {
      this.index = this.getRandomInt(1, 128);
      this.index2 = this.getRandomInt(1, 39);
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    setupCountdownTimer() {
      let timer = setInterval(() => {
        this.countdown = dayjs("2021-10-1 18:00").valueOf() - dayjs().valueOf();

        if (this.countdown <= 0) {
          this.countdown = 0;
          this.text = "NOW";
          clearInterval(timer);
        }
      }, 1000);
    },

    millisToMinutesAndSeconds(millis) {
      if (millis) {
        /*const hours = Math.floor(millis / (1000 * 60 * 60))
      const minutes = Math.floor(millis / (1000 * 60))
      const seconds = ((millis % 60000) / 1000).toFixed(0)*/
        return dayjs(millis).format("HH:mm:ss");
      }
      return this.text;
      //return hours + ':' + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
    },
  },
  computed: {
    getBro() {
      return require("../assets/example-bros/" + this.index + ".gif");
      //The value is no lower than min (or the next integer greater than min if min isn't an integer), and is less than (but not equal to) max.
    },
    getBro2() {
      return require("../assets/alpha/" + this.index2 + ".gif");
      //The value is no lower than min (or the next integer greater than min if min isn't an integer), and is less than (but not equal to) max.
    },
  }
};
</script>
<style lang="scss">
.inline-img-container {
    display: inline-block;
    width: 100%;
}
.inline-img-container img.inline-right {
    float: left;
    width: 10vw;
    min-width: 100px;
    padding-right: 10px;
}
.bg-img-container {
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    min-width: 100%;
    min-height: 100vh;
    width: unset;
    height: auto;
}
.bg-img-container::after {
    content: "";
    display: block;
    width: 100%;
    height: 160px;
    background: linear-gradient(transparent, #141422);
    /* background: #ffffff50; */
    position: relative;
    bottom: 160px;
    left: 0;
    /* z-index: -1; */
}
div.astro-container {
  margin: 10px;
}
.image-container {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
}
.image-container a {
  max-width: 300px;
  display: inline-flex;
  margin: 20px;
}
img.inline {
  width: 100%;
}
img.bro-left {
    margin-left: -540px;
    left: 10vw;
    position: absolute;
    z-index: -1;
    top: 20px;
}
img.bro-right {
    margin-right: -540px;
    right: 10vw;
    position: absolute;
    z-index: -1;
    top: 20px;
}
.bro-container {
    display: flex;
    position: relative;
        margin-bottom: 30px;
        cursor: pointer;
            box-shadow: #8637de 0 0 30px -15px;
}
.bro-container .text-box {
    padding: 30px;
}
.bro-container span.interactive {
    background: #cf2549;
    padding: 5px 10px;
    top: 0px;
    transform: translateX(50%);
    right: 50%;
    border-radius: 0px 0 10px 10px;
    position: absolute;
    color: white;
    z-index: 1;
}
.bro-cropper {
    width: 30vw;
    background: #1f1c3b;
    background: linear-gradient(#1f1c3b, #cf2549);
    border: solid 2px #cf2549;
    border-radius: 10px;
    /*border-right: solid 20px #cf2549;*/
    max-width: 450px;
    position: relative;
}
.bro-cropper .bro {
    left: max(-15vw, -160px);
    position: relative;
    width: 60vw;
    max-width: 800px;
    /* max-width: 500px;*/
}
div.astro-container {
  margin: 10px;
}
h2.subheading {
  font-size: 45px;
  margin: 0;
  padding: 0;
}
.countdown h1 {
  margin: 10px;
}
img.fullsize {
    min-width: 100%;
    min-height: 100vh;
    width: unset;
    height: auto;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* position: fixed; */
    /* position: absolute; */
    /* z-index: -1; */
    opacity: 0.15;
    top: 0;
}
.main-text.as-textbox h2 {
  color: white;
  font-size: 1.3rem;
}
.countdown {
  font-size: 60px;
  text-shadow: -2px -2px 0px #ffffff, 2px -2px 0px #ffffff, -2px 2px 0px #ffffff,
    2px 2px 0px #ffffff, 2px -2px 0px #ffffff, -2px 0px 0px #ffffff,
    0px 2px #ffffff, 2px 0px 0px #ffffff, 0px -2px #ffffff, 0px 0px 30px #8637de;
  color: #ff1442;
}
.main-text {
  display: block;
  width: 80%;
  margin: 0 auto;
  background: white;
  padding: 20px;
  border-radius: 5px;
}

div.as-textbox {
  width: 80%;
  margin: 60px auto;
  padding: 30px;
  box-sizing: border-box;
  border: solid 2px #5e2781;
  background: #141422;
  display: inline-block;
}
.main-text.as-textbox .bro-container {
    float: left;
    margin-right: 70px;
}
.bro-cropper img.alphabro {
    width: 100%;
    left: 0;
}
.bro-container.alpha .bro-cropper {
    border: none;
    background: none;
}
.bro-container.alpha span.interactive {
    background: #e37e00;
    top: unset;
    bottom: 4px;
    border-radius: 10px 10px 0px 0;
}
img.inline-icon {
    height: 32px;
    position: relative;
    display: inline-block;
    top: 9px;
    padding-left: 5px;
}
.main-text.as-textbox .bro-container.alpha {
    float: right;
    margin-right: 0;
    margin-left: 70px;
}
.inline-img-container hr {
    margin-bottom: 30px;
}
</style>
