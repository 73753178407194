<template>
  <div class="home">
    <div class="fw header-img">
      <img class="main-logo" src="https://api.astrobros-nft.com/public/astrobros/logos/astrobros-blank.png" />
    </div>
    <img class="bg-img" src="../assets/space-animation.gif" />
    <img class="bg-img roadmap-bg" src="../assets/roadmap-bg.jpg"  @load="onImgLoad" />
    <template v-if="animatedValue >= 100">
      <img class="bg-img exp" src="../assets/explosion.gif" />
    </template>
    <div class="roadmap-items">
      <img class="bg-img roadmap-bg spacer" src="../assets/roadmap-bg.jpg" />
      <div class="rm-bar">
        <div ref="innerBar" class="rm-inner-bar">
          <span>{{animatedValue.toFixed(2)}}%</span>
        </div>
      </div>
      <div ref="dialogue" class="node" :class="{'open': open}" @click="open = !open">
        <div>
          <h2>{{roadMapItems[activeNode].title}}</h2>
          <hr />
          {{roadMapItems[activeNode].text}}
        </div>
      </div>
      <img ref="rocketman" class="bro bro-r" src="../assets/example-bros/rocketman.gif" />
      <div class="shadow-container">
        <img ref="rocketmanShadow" class="rocketman-shadow" src="../assets/shadow.png" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import Map from "@/components/Map.vue";

  import axios from 'axios';
  
export default {

  name: "Roadmap",
  components: {
    //Map,
  },
  data() {
    return {
      open: 0,
      animatedValue: 0,
      timer: null,
      isLoaded: false,
      unlockPerc: 0,
      activeNode: 0,
      nextNode: [10,20,33,50,65,80,100],
      roadMapItems: [
        {
          "title":"Release the Astrobros!",
          "text":"Astrobros have been released! 10,000 squidgy, powerful eggs are now found floating throughout the cosmos. Perhaps you should pick one up and see what POWER lays dormant inside?",
        },
        {
          "title":"External Listings",
          "text":"We will set out to list on as many 3rd party tools as possible. We will also funnel a substantial amount of profits into further advertising both Astrobros and IMX to gain more users and more value to the project for our early supporters. More secondary markets will be approached and Astrobros will begin to powerlift their way to the Universal Core!",
        },
        {
          "title":"$10,000 Bronation",
          "text":"$10,000 in donations to a randomly drawn selection of Astrobros holders!",
        },
        {
          "title":"Gallery and Merch",
          "text":"The Astrobro Hall of Fame will open up, showcasing all minted bros and assets. The website will also undergo an expansion including merch and other ways to show your support for The Holy Tricep!",
        },
        {
          "title":"Gallery",
          "text":"The Astrobro Hall of Fame will open up, showcasing all minted bros.",
        },
        {
          "title":"Research and the 5 Golden Bros",
          "text":"We will begin a phase of research to find out what types of content the Astrobros universe could benefit from. Bro naming, yield farming, cross-network swaps, gender swapping, HD vector avatar versions of your bro and more. This is where the next step for the Astrobros universe begins to be formed. We will also donate 5 exclusive 1/1 golden bros at random to Astrobro holders",
        },
        {
          "title":"MegaBros - Unleashed!",
          "text":"At 75% purchased we will begin channeling an ancient builder-civilisation who devouted there existence to becoming machines. They will teach us strange, otherworldy artforms that can create gigantic hybrids from two given Astrobros. This will be done non-destructivly to ensure a stable supply of ABROS. Limited Edition Astrobros might even make something special... Hmm... This process will require a lot of new artwork so will take time to complete but hitting this milestone will make it happen.",
        },
        {
          "title":"Bronations",
          "text":"$25,000 in donations to a randomly drawn selection of Astrobros holders and another $50,000 to go towards a charity of our and the communitys choosing! Work begins on the next part of the Astrobros universe. That might be a game using your bro as an avatar, a new collection for the same universe of different entities and more! Each decision made will be done to enhance the utility of Astrobros.",
        },
      ]
    };
  },
  watch:{
    unlockPerc() {
      this.$nextTick(() => {
        this.timer = window.setInterval(() => {
          this.nodePoint = this.nextNode[this.activeNode]
          if (this.animatedValue >= this.nodePoint) {

            for (let index = 0; index < this.nextNode.length; index++) {
              if (this.unlockPerc > this.nextNode[index]) {
                this.activeNode = this.activeNode+1
              } else {
                break
              };
              
            }
          }
          //this.rocketStart();
          //console.log(this.animatedValue)
          if (this.animatedValue < this.unlockPerc) {
            this.animatedValue = this.animatedValue + (this.unlockPerc/200)
            //console.log('this.animatedValue')
            //console.log(this.animatedValue)
           this.rocketStart()
          } else {
            //alert('stop pls')
            this.animatedValue = this.unlockPerc
            clearInterval(this.timer);  
          }
        },10);
      this.rocketStart()
      })
      //window.scrollTo(0, (document.body.scrollHeight/this.unlockPerc)*100 || (document.documentElement.scrollHeight/this.unlockPerc)*100);
      if (this.unlockPerc > 0) {
      this.$refs.rocketman.classList.add('slide-top')
      } else {
        this.$refs.rocketman.classList.remove('slide-top')
      }
    },
    isLoaded() {
      //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      this.$nextTick(() => {
        //window.scroll({top: 0, behavior: "smooth"})
      })
    }
  },
  created () {
    
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    
    //axios.get('https://api.astrobros-nft.com/stats/percent').then((response) => { this.unlockPerc = 100; console.log(response.data.data.perc_hatched) })
    axios.get('https://api.astrobros-nft.com/stats/percent').then((response) => { 
      this.unlockPerc = response.data.data.perc_ready 
      //this.unlockPerc = 1
      })
      //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    rocketStart() {
         this.$refs.innerBar.style.height = "calc("+Math.min(100, this.animatedValue)+"% - "+ (this.animatedValue/10) +"px)";
          const wHeight = (document.body.scrollHeight - document.documentElement.clientHeight)
          this.$refs.rocketman.style.bottom = (93*(this.animatedValue/100)+1)+'%';
          let scaled = 1 + ( ((this.animatedValue)/100) * 2)*10 
          //console.log(scaled)
          this.$refs.rocketmanShadow.style.transform = "scale("+ Math.min(scaled, 3) +")";
          this.$refs.rocketmanShadow.style.opacity = 0.8 - (0.8 * ((this.animatedValue/100))*30);
          window.scrollTo(0, wHeight - (wHeight*(this.animatedValue/100)))

    },
    onImgLoad () {
      this.isLoaded = true
    },
    handleScroll () {//
        let perc = 100 - ((document.documentElement.scrollTop / (document.body.scrollHeight - document.documentElement.clientHeight)) * 100)

          
          if (perc >= this.nextNode[this.activeNode]) {
            this.activeNode = this.activeNode+1
            /*console.log(perc)
            for (let index = 0; index < this.nextNode.length; index++) {
              if (this.unlockPerc > this.nextNode[index]) {
                this.activeNode = this.activeNode+1
              } else {
                break
              };*/
              
            } else if (perc < this.nextNode[this.activeNode-1]) {
              if (this.activeNode != 0) {
              this.activeNode = this.activeNode-1
              }
            }
            
            if (this.activeNode == 0 || this.unlockPerc > this.nextNode[this.activeNode-1] || this.unlockPerc >= 100 ) {
              this.$refs.dialogue.classList.add("green")
          } else {
            if (this.$refs.dialogue.classList.contains("green")) {
              this.$refs.dialogue.classList.remove("green")
              }
          }

   /* console.log(event)
    let perc = 100 - ((document.documentElement.scrollTop / (document.body.scrollHeight - document.documentElement.clientHeight)) * 100)
    console.log(perc)
   
      if(this.timer !== null) {
       //console.log('a')
        this.scrolling = 1
        clearTimeout(this.timer);        
      }
      this.timer = setTimeout(()=>{
            // do something
            //console.log('b')
            this.scrolling = 0
      }, 150);  */    
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
hr {
  outline: white 1px solid;
  border: unset;
}
.node.green h2:before {
  font-size: 35px;
    content: "✓";
    background: white;
    margin-right: 10px;
    color: #79d782;
    width: 40px;
    border-radius: 50px;
    height: 40px;
    line-height: 1.3;
    position: relative;
    display: inline-block;
    border: solid #5cf08a 4px;
}
.rm-inner-bar span {
    background: #7781dd;
    color: #8637de;
    font-weight: bold;
    background: white;
    border: solid #5cf08a 3px;
    padding: 5px;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    position: absolute;
    font-style: italic;
    border-radius: 10px;
}
.node{
  font-family: "Advent Pro", sans-serif;
  font-size: 1.4em;
    position: fixed;
    top: 50%;
    right: 3%;
    transform: translate(0%, -50%);
    width: 35%;
    background: #343f7ed1;
    color: white;
    border: solid 4px white;
    box-shadow: 0 0 0 8px silver;
    padding: 20px;
    box-sizing: unset;
    z-index: 999;
        border-radius: 10px;

}
.node.green {
    background: #33b74fd1;
    box-shadow: 0 0 0 8px #68c0c0;
}
.home {
    position: relative;
}
img.bg-img.roadmap-bg {
    filter: unset;
    position: absolute;
    margin-top: 0px;
    top: -40px;
}
.node h2 {
    margin: 0;
    margin-bottom: 20px;
}
img.bro {
    width: 80%;
    position: absolute;
    bottom: 1%;
    left: 0;
    max-width: 1000px;
    transform: translateX(-50%);
    left: 36%;
    pointer-events: none;
}
img.bro-r {
  -webkit-transform: translateX(-50%) scaleX(-1);
  transform: translateX(-50%) scaleX(-1);
}
.shadow-container {
      width: 75%;
    position: absolute;
    bottom: 0;
    overflow-x: hidden;
}
img.rocketman-shadow {
    position: relative;
    bottom: 60px;
    opacity: 0.8;
    width: 100%;
        max-width: 1000px;
    /* left: 0; */
    /* margin: 0 auto; */
    /* display: block; */
    z-index: -1;
}
.roadmap-items {
    /* position: fixed; */
    position: relative;
    width: 100%;
    height: 100%;
    bottom: 0;
}
img.bg-img.roadmap-bg.spacer {
    opacity: 0;
    /* display: none; */
    position: relative!important;
        height: 100%;
    margin-top: -350px;
}
.rm-bar {
    display: block;
    height: 100%;
    width: 16px;
    position: absolute;
    background: #6e6e7a;
    left: 10%;
    bottom: 100px;
}
.rm-inner-bar {
    display: block;
    height: 0%;
    width: 8px;
    position: absolute;
    background: #5cf08a;
    left: 4px;
    bottom: 5px;
}
img.bg-img.exp {
    filter: unset;
    top: -100px;
    min-width: 100%;
    min-height: 100vh;
    width: unset;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 512px) {

  .roadmap-items .node {
    width: 100%;
    right: 0;
    transform: unset;
    box-sizing: border-box;
    bottom: 0;
  }
  .roadmap-items .node.open {
    height: 100%;
    top: 0;
    z-index: 999;
  }
  .roadmap-items .node:not(.open) {
    height: 95px;
    overflow: hidden;
    bottom: 0;
    top: unset;
    position: fixed;
}
.roadmap-items {
    margin-bottom: 90px;
}
.node h2 {
    font-size: 1em;
}
.roadmap-items .node:not(.open) hr {
    display: none;
}

.node.green h2:before{
      zoom: 0.5;
}
.roadmap-items .node:not(.open)::after {
    content: "";
    width: 100%;
    height: 50px;
    background: linear-gradient(
360deg, #5cf08a, transparent);
    bottom: 0;
    left: 0;
    position: absolute;
}
.roadmap-items .node:not(.green):not(.open)::after {
    content: "";
    width: 100%;
    height: 50px;
    background:linear-gradient(360deg, #6057da, transparent);
    bottom: 0;
    left: 0;
    position: absolute;
}
img.bro {
  left: 50%;
}
.shadow-container {
      width: 100%;
}

img.rocketman-shadow {
  bottom: 20px;
}

}



.slide-top {
	-webkit-animation: slide-top 0.85s ease-in-out infinite alternate both;
	        animation: slide-top 0.85s ease-in-out infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-9-27 0:0:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateX(-50%) scaleX(-1) translateY(0);
            transform: translateX(-50%) scaleX(-1) translateY(0);
  }
  100% {
    -webkit-transform: translateX(-50%) scaleX(-1) translateY(-40px);
            transform: translateX(-50%) scaleX(-1) translateY(-40px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateX(-50%) scaleX(-1) translateY(0);
            transform: translateX(-50%) scaleX(-1) translateY(0);
  }
  100% {
    -webkit-transform: translateX(-50%) scaleX(-1) translateY(-40px);
            transform: translateX(-50%) scaleX(-1) translateY(-40px);
  }
}

</style>
