<template v-if="astrobro">
  <div class="modal-container">
    
    <div class="modal inventory stargrid">
      
      <h1>Astrobro viewer:</h1>
      <div class="modal-close-container" @click="closeModal(true)">
        <i class="fa fa-times"></i>
      </div>
      <div class="inventory-container">
        <div class="img-selector-container">
          <img :src="imageDisplay+'?v='+v+hasHatched" />
            <div class="btn-container" v-if="Object.keys(actualAttributes).length == 0">
              <div class=button @click="hatchEgg()">Hatch Egg</div>
            </div>
            <div class="btn-container"  v-if="Object.keys(actualAttributes).length > 0">
              <div class="button" @click="imgType = 0">Default</div>
              <div class="button" @click="imgType = 4">Full</div>
              <div class="button grey">Cutout</div>
            </div>
            <div class="btn-container"  v-if="Object.keys(actualAttributes).length > 0">
              <div class="button" @click="imgType = 1">Avatar Zoom 1</div>
              <div class="button" @click="imgType = 2">Avatar Zoom 2</div>
              <div class="button" @click="imgType = 3">Avatar Zoom 3</div>
            </div>
        </div>
        <div class="item-content">
          <h1>
            {{ dynamicData.name }}<span> Stored in {{ astrobro.status }}</span>
          </h1>
          <p>{{ dynamicData.description }}</p>
          <hr />
          <h2>
            {{ dynamicData.name }}<span> Attributes </span>
          </h2>
          <div class="attribute-container" v-if="Object.keys(actualAttributes).length > 0">
            <div
              class="attribute"
              :key="index"
              v-for="(item, index) in actualAttributes"
            >
              {{ typeName(index) }} <span>{{ item }}</span>
            </div>
          </div>
          <div class="attribute-container" v-else>
            <div
              class="attribute"
             >
              Type <span>Egg</span>
            </div>
          </div>
          
        </div>

      </div>
      <ModalHatch :mute="mute" v-if="hatchOpen == 1" @closed="(hatchOpen = 0, hasHatched = 1)"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import omit from 'lodash.omit';

import ModalHatch from "@/components/ModalHatch.vue";

export default {
  name: "ModalItem",
  props: {
    astrobro: Object,
    mute: Boolean,
    v: Number,
  },
  components: {
    ModalHatch
  },
  data() {
    return {
      imgType: 0,
      actualData: {},
      hasHatched: 0,
      hatchOpen: 0,
      remainingCalls: 15,
    };
  },
  watch: {
    hasHatched() {
      console.log('refreshing')
      axios.get('https://api.astrobros-nft.com/bros/data/imx/'+this.astrobro.token_id).then(response => (this.actualData = response.data))
    },
    actualAttributes() {
      if (this.actualAttributes && this.actualAttributes.bg){
      this.hasHatched = 1
      console.log('refreshing')
    }
    }
  },
  created() {
    axios.get('https://api.astrobros-nft.com/bros/data/imx/'+this.astrobro.token_id).then(response => (this.actualData = response.data))
  },
  methods: {
    hatchEgg() {
      axios.get('https://api.astrobros-nft.com/hatchEgg/'+this.astrobro.token_id+"?v="+btoa(this.astrobro.user)).then(response => {
        if (response.data.data.hatched == 1) {
          this.hatchOpen = 1
          //alert('hatch')
        }
        console.log
        /*this.hatchOpen = 1
        console.log(this.hasHatched = response.data.data.hatched)*/
      } )
    },
    closeModal(event) {
      //console.log('clicking')
      this.$emit("closed", event);
    },
    filterAttr(attr, whitelist) {
      return omit(attr, whitelist);
    },
    typeName(name) {
      switch (name) {
        case "bg":
          return "Background";
        default:
          return name.replace(/[-_]/g, " ");
      }
    },
  },
  computed: {
    dynamicData() {
      if (this.actualData) {
        return this.actualData
      } else {
        return this.astrobro
      }
    },
    actualAttributes() {
      return this.filterAttr(this.actualData, [
                'description',
                'external_url',
                'image',
                'name',
                'avatar',
                'full_image',
                'cutout'
              ])
    },
    imageDisplay() {
      if (!this.actualAttributes || Object.keys(this.actualAttributes).length == 0) {
        return this.astrobro.image_url
      }
      if (this.imgType == 1){
        return this.actualData.avatar.avatar_l
      } else if (this.imgType == 2){
        return this.actualData.avatar.avatar_m
      } else if (this.imgType == 3){
        return this.actualData.avatar.avatar_s
      } else if (this.imgType == 4){
        return this.actualData.full_image
      } else if (this.imgType == 5){
        return this.actualData.cutout
      }
      return this.astrobro.image_url
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn-container .button.grey {
    background: grey!important;
    color: darkgrey!important;
    pointer-events: none;
}
.modal-container::before {
  content: "";
  position: fixed;
  background: #281038c7;
  width: 100%;
  height: 100%;
  display: block;
  bottom: 50%;
  right: 50%;
  transform: translateX(50%) translateY(50%);
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: overlay;
    z-index: 100;
}

.modal.inventory.stargrid > h1 {
  display: inline;
}
.modal .inventory-container img {
  width: 100%;
  /*height: 100%;*/
  border: solid 2px #ffffff;
  border-radius: 5px;
}
.modal .inventory-container {
  display: flex;
  clear: both;
      flex-flow: wrap;
}
.modal .inventory-container>div {
    /*min-width: 340px;*/
    flex: 1;
    margin: 10px;
}
.modal-close-container {
  font-size: 40px;
  padding: 0px 10px 10px 10px;
  float: right;
  cursor: pointer;
}
.modal-close-container:hover {
    color: #c8ffbc;
    }
.modal {
position: relative;
    width: 80%;
        top: 5vh;
    /* bottom: 50%; */
    /* right: 50%; */
    /* transform: translateX(50%) translateY(50%); */
    background: #141422;
    border-radius: 5px;
    border: solid 2px #20a15a;
    box-shadow: #ffffff 0px 8px 7px -10px, #0aff52 0px 10px 25px -12px, #005eaa 0px 10px 40px -15px;
        margin-bottom: 100px!important;
}
.item-content {
    margin: 0 0px 0 20px;
    background: white;
    color: #43436a;
    padding: 20px;
    font-weight: bold;
    border-radius: 5px;
}

.item-content h1 {
  padding: 10px;
  margin: 0;
  background: #a12c20;
  color: #ffffff;
  font-weight: bold;
}
.attribute-container {
      display: inline-flex;
    flex-flow: wrap;
  /* padding: 10px; */
}
.attribute {
margin: 10px;
    background: #572578;
    padding: 5px 0px 5px 20px;
    border-radius: 25px;
    color: white;
    text-transform: capitalize;
    flex: auto;
    display: inline-flex;
    align-items: baseline;
}
.attribute > span {
    margin: 0px 4px 0px 10px;
    background: #7f35b0;
    padding: 7px 20px;
    border-radius: 25px;
    flex: auto;
}
.btn-container {
        /*display: inline-flex;*/
            display: flex;
    flex-flow: wrap;
}
.btn-container .button {
  flex: auto;
    padding: 7px 10px;
    background: #91f87b;
    color: #141422;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s;
        box-shadow: #ffffff 0px 0px 0px -10px, #0aff52 0px 0px 0px -12px, #005eaa 0px 0px 0px -15px;
}
.btn-container .button:hover {
    box-shadow: #ffffff 0px 8px 7px -10px, #0aff52 0px 10px 25px -12px, #005eaa 0px 10px 40px -15px;
    color: #141423;
    background: #c8ffbc;
}
div.inventory {
    margin: 0px auto;
}
</style>
