import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { Link } from '@imtbl/imx-sdk';
Object.defineProperty(Vue.prototype, '$link', { value: Link });

import { ERC721TokenType, MintableERC721TokenType, ImmutableMethodResults, ETHTokenType, EthAddress, ImmutableOrderStatus } from '@imtbl/imx-sdk';
Object.defineProperty(Vue.prototype, '$linkTypes', { value: { ERC721TokenType, MintableERC721TokenType, ImmutableMethodResults, ETHTokenType, EthAddress, ImmutableOrderStatus } });

import { ImmutableXClient } from '@imtbl/imx-sdk';
Object.defineProperty(Vue.prototype, '$imxClient', { value: ImmutableXClient });
//({"registrationContractAddress":process.env.VUE_APP_ROPSTEN_REGISTRATION_ADDRESS})
process.env.VUE_APP_ROPSTEN_LINK_URL
import { ethers } from 'ethers';
Object.defineProperty(Vue.prototype, '$ethers', { value: ethers });

//require('dotenv').config();

//const link = new this.$link(process.env.REACT_APP_ROPSTEN_LINK_URL)
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
