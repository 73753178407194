import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Inventory from "../views/Inventory.vue";
import Store from "../views/Store.vue";
import Roadmap from "../views/Roadmap.vue";

Vue.use(VueRouter);

/*const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/store",
    name: "Store",
    component: Store,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    component: Roadmap,
  }
];
*/
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/store",
    name: "Store",
    component: Store,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    component: Roadmap,
  }
];

const router = new VueRouter({
  routes,
});

export default router;
