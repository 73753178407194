<template>
  <div class="home">
    <img class="bg-img" src="../assets/space-animation.gif" />
    <StoreComp />
  </div>
</template>

<script>
// @ is an alias to /src
import StoreComp from "@/components/StoreComp.vue";

export default {
  name: "Store",
  components: {
    StoreComp,
  },
};
</script>
